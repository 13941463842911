var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"height-100p flex-view flex-direction-row"},[_c('div',{staticClass:"side-menu",attrs:{"id":"nav-menu"}},[_c('app-sidebar')],1),_c('div',{staticClass:"poppup-cp-new-overlay",attrs:{"id":"nav-menu-shadow"}}),_c('div',{staticClass:"flex-view"},[_c('div',{staticClass:"flex-view",attrs:{"id":"nav-bar"}},[_c('app-header')],1),_c('div',{staticClass:"right-side-responsive font-basis-regular",attrs:{"id":"main-body"}},[_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"max-width top-spacing right-inner-width"},[_vm._m(0),_c('div',{staticClass:"assignment-courses-card mt-4"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn-np btn-contained",on:{"click":function($event){return _vm.assignmentSubmitOpen()}}},[_vm._v("Sumbit my assignment now")])])]),(_vm.assignmentSubmit)?_c('div',{staticClass:"assignment-upload-popup"},[_c('div',{staticClass:"assignment-upload-popup-wrapper"},[_c('div',{staticClass:"position-absolute close-icon",on:{"click":function($event){return _vm.assignmentSubmitClose()}}},[_c('img',{attrs:{"src":require("../../../assets//assignment/cross-icon.svg"),"alt":""}})]),_vm._m(4),_c('h6',{staticClass:"font-basis-bold text-center mb-5"},[_vm._v(" How to upload an assignment? ")]),_c('p',[_vm._v("Dear saad your assignment has been submitted to Instructor, Sit est, ornare venenatis nisl eu suspendisse et, purus. Suspendisse est condimentum in urna. Sed cursus dui, a, habitant. Lorem ipsum dolor sit amet, ")]),_vm._m(5)])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assignment-courses-card"},[_c('div',{staticClass:"assignment-courses-header d-flex justify-content-between"},[_c('div',{staticClass:"assignment-courses-header-title"},[_c('img',{attrs:{"src":require("../../../assets/assignment/course-icon.svg"),"alt":""}}),_c('div',[_c('h6',[_vm._v("Assignment name")]),_c('ul',[_c('li',[_vm._v("Course name ")]),_c('li',[_vm._v("Module name ")])]),_c('p',[_vm._v("Posted on 12-12-2020")])])]),_c('div',[_c('button',{staticClass:"btn-np btn-contained"},[_vm._v(" Submit Now ")])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12 col-lg-6 mb-4 mb-lg-0"},[_c('div',{staticClass:"assignment-courses-card child d-flex align-items-center shadow-none"},[_c('div',{staticClass:"assignment-courses-card-desc"},[_c('p',[_vm._v("In this section we can give your user a visual of the insrtuctions that has been added by the instructor or we can show our user some question of assignment.")])])])]),_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3 mb-md-0"},[_c('div',{staticClass:"assignment-courses-matrix-card"},[_c('img',{attrs:{"src":require("../../../assets/assignment/badge-icon.svg"),"alt":""}}),_c('p',[_vm._v("Marks")]),_c('h6',[_vm._v("60"),_c('span',[_vm._v("2020")])])])]),_c('div',{staticClass:"col-md-4 mb-3 mb-md-0"},[_c('div',{staticClass:"assignment-courses-matrix-card"},[_c('img',{attrs:{"src":require("../../../assets/assignment/timer-icon.svg"),"alt":""}}),_c('p',[_vm._v("Opened date ")]),_c('h6',[_vm._v("20 Mar"),_c('span',[_vm._v("2020")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"assignment-courses-matrix-card"},[_c('img',{attrs:{"src":require("../../../assets/assignment/hour-glass-icon.svg"),"alt":""}}),_c('p',[_vm._v("Due date")]),_c('h6',[_vm._v("20 Mar"),_c('span',[_vm._v("2020")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"font-basis-bold"},[_vm._v("Your assignment status")]),_c('button',{staticClass:"btn-np outlined"},[_vm._v("Cancel upload")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-noti-uploading flex-view align-items-center"},[_c('p',[_vm._v("Uploading ")]),_c('div',{staticClass:"snippet",attrs:{"data-title":".dot-flashing"}},[_c('div',{staticClass:"stage"},[_c('div',{staticClass:"dot-flashing"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-noti-upload-file flex-view align-items-center justify-content-between"},[_c('div',{staticClass:"flex-view align-items-center"},[_c('img',{attrs:{"src":require("../../../assets/assignment/pdf-icon.svg"),"alt":""}}),_c('div',{staticClass:"upload-file-title"},[_c('p',[_vm._v("assignment for the nearpeer.pdf "),_c('span',[_vm._v("Your file is larger than 60MB. It may take some time.")])])])]),_c('div',{staticClass:"upload-file-percent"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",staticStyle:{"width":"70%"},attrs:{"role":"progressbar","aria-valuenow":"70","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" 70% ")])]),_c('p',{staticClass:"font-basis-bold"},[_vm._v("80 "),_c('span',{staticClass:"font-basis-regular"},[_vm._v("mb")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assignment-upload-done"},[_c('img',{attrs:{"src":require("../../../assets/assignment/done.gif"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn-np outlined mb-3"},[_vm._v("View submitted assignments")]),_c('button',{staticClass:"btn-np btn-contained"},[_vm._v("View more assignments")])])
}]

export { render, staticRenderFns }