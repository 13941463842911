<template src="./assignment-submitted.html"></template>

<script>
// import customStorageService from "../app-services/custom-storage-service";
// import appService from "../app-services/app-service";
import appHeader from "../../shared-module/components/app-header/app-header";
import appFooter from "../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "../../shared-module/components/app-sidebar/app-sidebar.vue";
import AppVerification from "../../shared-module/components/app-verification/app-verification";
import VueApexCharts from 'vue-apexcharts'


export default {
  name: "assignment-detail",
  data() {
    return {
      assignmentSubmit: false,
      series: [50],
      chartOptions: {
        chart: {
          width: 153,
          height: 153,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '60%',
            }
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ['Step'],
      },
    };
  },
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "app-verification": AppVerification,
    apexchart: VueApexCharts,
  },
  methods: {
    assignmentSubmitOpen() {
      this.assignmentSubmit = true;
    },
    assignmentSubmitClose() {
      this.assignmentSubmit = false;
    }
  },
  mounted() {}
};
</script>

<style src="./assignment-submitted.css" scoped></style>
