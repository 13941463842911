<template src="./assignment-detail.html"></template>

<script>
// import customStorageService from "../app-services/custom-storage-service";
// import appService from "../app-services/app-service";
import appHeader from "../../shared-module/components/app-header/app-header";
import appFooter from "../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "../../shared-module/components/app-sidebar/app-sidebar.vue";
import AppVerification from "../../shared-module/components/app-verification/app-verification";
import Datepicker from "vuejs-datepicker";

export default {
  name: "assignment-detail",
  data() {
    return {
      assignmentUpload: false
    };
  },
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "app-verification": AppVerification,
    Datepicker
  },
  methods: {
    assignmentUploadOpen() {
      this.assignmentUpload = true;
    },
    assignmentUploadClose() {
      this.assignmentUpload = false;
    }
  },
  mounted() {}
};
</script>

<style src="./assignment-detail.css" scoped></style>
