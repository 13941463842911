import { render, staticRenderFns } from "./assignment-upload-solution.html?vue&type=template&id=75a81c65&scoped=true&"
import script from "./assignment-upload-solution.vue?vue&type=script&lang=js&"
export * from "./assignment-upload-solution.vue?vue&type=script&lang=js&"
import style0 from "./assignment-upload-solution.css?vue&type=style&index=0&id=75a81c65&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75a81c65",
  null
  
)

export default component.exports